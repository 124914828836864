import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { fonts, mediaQueries, colors } from "../../styles/css-utils"
import { navlinkStyle } from "../Footer"
import SubNav from "../SubNav"

export default ({ title }) => (
  <div
    css={css`
      position: fixed;
      height: 100vh;
      width: 40vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #19258f;
      color: white;
      font-family: ${fonts.sans};
      ${mediaQueries.phoneLarge} {
        display: block;
        position: static;
        width: 100%;
        height: 100%;
        padding: 300px 0;
      }
    `}
  >
    <div>
      <SubNav fill={colors.lightgray} />
      <div
        css={css`
          display: flex;
          justify-content: center;
          font-size: 50px;
          font-weight: 500;
          padding-top: 50px;
          &:before {
            content: "";
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            position: relative;
            top: 20px;
            margin-right: 13px;
            background-color: white;
            display: inline-block;
          }
        `}
      >
        {title}
      </div>
    </div>
    <div
      css={css`
        display: flex;
        ${mediaQueries.phoneLarge} {
          display: none;
        }
      `}
    >
      <ul
        css={css`
          list-style: none;
          font-size: 1.4rem;
          font-weight: 500;
          li {
            padding: 0.45rem 0 0 0;
          }
          li:before {
            content: "";
            height: 1.4rem;
            width: 1.4rem;
            border-radius: 50%;
            position: relative;
            top: 4px;
            margin-right: 8px;
            background-color: ${colors.lightblue};
            display: inline-block;
            border: 1px solid white;
          }
          li.active:before {
            border: none;
            background-color: white;
          }
        `}
      >
        <li className={title === "STUDIO" ? "active" : ""}>
          <Link to="/studio" css={navlinkStyle}>
            STUDIO
          </Link>
        </li>
        <li className={title === "EDUCATION" ? "active" : ""}>
          <Link to="/education" css={navlinkStyle}>
            EDUCATION
          </Link>
        </li>
        <li className={title === "BRAND" ? "active" : ""}>
          <Link to="/brand" css={navlinkStyle}>
            BRAND
          </Link>
        </li>
      </ul>
    </div>
  </div>
)
