import React from "react"
import { navlinkStyle } from "../Footer"
import { fonts, mediaQueries } from "../../styles"
import { css } from "@emotion/core"

export default () => (
  <div
    css={css`
      background-color: #030b5d;
      color: white;
      font-family: ${fonts.sans};
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
      ${mediaQueries.phoneLarge} {
        display: none;
      }
    `}
  >
    <span
      css={css`
        margin-right: 40px;
      `}
    >
      TEL:{" "}
      <a href="tel:0368753371" css={navlinkStyle}>
        +81 03-6875-3371
      </a>
    </span>
    <span>
      WECHAT:{" "}
      <a href="weixin://" css={navlinkStyle}>
        kiyomizu_art
      </a>
    </span>
  </div>
)
