import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { mediaQueries, colors } from "../../styles"

const linkStyle = css`
  color: ${colors.deepblue};
`

export default () => (
  <ul
    css={css`
      display: flex;
      justify-content: space-around;
      font-size: 1.4rem;
      font-weight: 600;
      list-style: none;
      margin: 0;
      padding: 1rem 0 0 10vw;
      ${mediaQueries.phoneLarge} {
        display: none;
      }

      li:before {
        content: "";
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 50%;
        border: 1px solid ${colors.deepblue};
        position: relative;
        top: 4px;
        margin-right: 8px;
        background-color: white;
        display: inline-block;
      }

      ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        padding-left: 0;
        left: 0;
        top: 21px;
        display: none;
        background: white;
      }

      li {
        position: relative;
      }

      li:hover > ul,
      li ul:hover {
        visibility: visible;
        opacity: 1;
        display: block;
        min-width: 250px;
        text-align: left;
        box-shadow: 0px 3px 5px -1px #ccc;
      }

      li ul li {
        clear: both;
        width: 100%;
        text-align: left;
        padding: 10px;
        margin: 0;
        list-style: none;
        transition: all 0.5s ease;
      }

      li ul li:not(:first-of-type) {
        border-top: 1px solid gray;
      }

      li ui li:before {
        border-style: none;
      }

      li ul li:hover {
        transition: all 0.3s ease;
        cursor: pointer;
        color: white;
        background-color: rgb(1, 9, 90);
      }
    `}
  >
    <li>
      <Link to="/" css={linkStyle}>
        清水艺术
      </Link>
    </li>
    <li>
      <Link to="/about" css={linkStyle}>
        关于清水
      </Link>
    </li>
    <li>
      <Link to="/schools" css={linkStyle}>
        学校介绍
      </Link>
      <ul>
        <li>东京五美大</li>
        <li>关西三美大</li>
        <li>国公立五艺大</li>
        <li>综合类高排名院校</li>
        <li>其他院校</li>
      </ul>
    </li>
    <li>
      课程 -{" "}
      <Link to="/class/normal" css={linkStyle}>
        学部
      </Link>
      ・
      <Link to="/class/master" css={linkStyle}>
        大学院
      </Link>
    </li>
  </ul>
)
