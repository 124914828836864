/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SubFooter from "../components/SubFooter"
import Sidebar from "../components/Sidebar"
import TopBar from "../components/TopBar"
import { mediaQueries } from "../styles"

export default () => {
  return (
    <Layout title="BRAND">
      <Sidebar title="BRAND" />
      {/* Main Content */}
      <div
        css={css`
          margin-left: 40vw;
          min-height: 100vh;
          display: grid;
          grid-template-rows: auto 1fr auto;
          ${mediaQueries.phoneLarge} {
            display: block;
            margin-left: 0;
          }
        `}
      >
        <TopBar />
        <ul></ul>
        {/* Footer */}
        <SubFooter />
      </div>
    </Layout>
  )
}
